import { gql, useMutation } from '@apollo/client';
import { usePandoroViewModel } from '@srlabs-srl/dashboard-hyper';
import { useLocation } from 'react-router-dom';
import { useHistory } from "react-router-dom";
import { useTranslation } from 'react-i18next';

const RESET_PASSWORD = gql`
mutation($newPassword:String, $token:String){
  resetPasswordFinalize(newPassword:$newPassword, token:$token){
    res
  }
}
`;

function useResetPasswordViewModel() {
  const [resetPassword] = useMutation(RESET_PASSWORD);
  const { t } = useTranslation();

  const history = useHistory();
  const location = useLocation();
  class ResetPasswordReducer {

    async passwordReset(state, newPassword) {
      const tokenUrl = location.pathname.split('/');
      const viewToken = tokenUrl[tokenUrl.length - 1];

      try {
        const result = await resetPassword({ variables: { newPassword, token: viewToken } });
        if (result.data.resetPasswordFinalize.res) { 
          state.errorMessage = null;
          history.push("/");
        }
      } catch (error) {
        state.errorMessage = t('confirmPage.configurationToken');
      }
      return state;
    }
  }
  return usePandoroViewModel(ResetPasswordReducer, {
    errorMessage: null
  });
}

export { useResetPasswordViewModel };
