import { gql, useMutation } from '@apollo/client';
import { usePandoroViewModel } from '@srlabs-srl/dashboard-hyper';
import { useTranslation } from 'react-i18next';

const RESET_EMAIL = gql`
mutation($email: String){
  resetPasswordStep1(email:$email){
    res
  }
}
`;

function useResetEmailViewModel() {
  const [resetEmail] = useMutation(RESET_EMAIL);
  const { t } = useTranslation();
  class ResetEmailReducer {
    async emailReset(state, email) {
      try {
        await resetEmail({ variables: { email } });
        state.successMessage = t('forgotPage.emailSent');
        state.errorMessage = null;
      } catch (error) {
        state.errorMessage = t('forgotPage.userNotExist');
        state.successMessage = null;
      }
      return state;
    }
  }
  return usePandoroViewModel(ResetEmailReducer, {
    successMessage: null,
    errorMessage: null
  });
}

export { useResetEmailViewModel };
