import React from 'react';
import { Row, Col } from 'reactstrap';
import { useTranslation } from 'react-i18next';

function AppFooterPanel(props) {
    const { t } = useTranslation();
    const VERSION = "1.5.0" //process.env.EYESPOT_VERSION;
    return <Row className=''>
        <Col md={4}>
            <Row className="footer-links d-flex justify-content-between">
                <p className="m-0">2022© SR Labs s.r.l.</p>
                <a target="_blank" href="https://www.srlabs.it/wp-content/uploads/2019/02/GDPR_Privacy.pdf">{t('bottomBar.privacy')}</a>
            </Row>
        </Col>
        <Col md={8} >
            <div className="text-md-right footer-links d-none d-md-block">
                {/*<a href="/">{t('bottomBar.support')}</a>
                <a href="/">{t('bottomBar.contactUs')}</a>*/}
                Version {VERSION}
            </div>
        </Col>
    </Row>;
}

export { AppFooterPanel };
