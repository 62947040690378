import React from 'react';
import { Cookies } from "react-cookie";
import { ApolloProvider, ApolloClient, createHttpLink, InMemoryCache } from "@apollo/client";
import { setContext } from "@apollo/client/link/context";
import { PandoroShell } from '@srlabs-srl/dashboard-hyper';
import { ShellModule } from './shell/shell-module';
import '@srlabs-srl/dashboard-hyper/dist/assets/scss/Modern-Dark.scss';
import '@srlabs-srl/dashboard-hyper/dist/assets/scss/Modern.scss';
import './app.css';
import '@syncfusion/ej2/bootstrap.css';

const initClient = () => {
    const cookies = new Cookies();
    let client = null;
    const BASE_URL = process.env.REACT_APP_API_ENDPOINT;

    let jwt = cookies.get('jwt');
    if (!jwt) {
        client = new ApolloClient({
          uri: BASE_URL,
          cache: new InMemoryCache({
              dataIdFromObject: (o) => {
            // eslint-disable-next-line no-unused-expressions
              o.id ? `${o.__typename}-${o.id}` : `${o.__typename}-${o.cursor}`;
            },
          }),
        });
    } else {
        const httpLink = createHttpLink({
            uri: BASE_URL,
        });
        const authLink = setContext((_, { headers }) => {
            return {
                headers: {
                    ...headers,
                    authorization: `Bearer ${
                        jwt.token
                    }`,
                }
            }
        });
        client = new ApolloClient({
          link: authLink.concat(httpLink),
          cache: new InMemoryCache({
              dataIdFromObject: (o) => {
            // eslint-disable-next-line no-unused-expressions
              o.id ? `${o.__typename}-${o.id}` : `${o.__typename}-${o.cursor}`;
            },
          }),
        });
    }
    return client;
}

const App = () => {
    return <ApolloProvider client={initClient()} >
        <PandoroShell module={ShellModule} />
    </ApolloProvider>;
};

export default App;