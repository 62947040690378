import React from 'react';
import logo from '../assets/images/login/logoeyespot.png';
import { useLoginViewModel } from './pages/login-vm';
import { allRoutes } from '../routes/routes';
import { AppFooterPanel } from './panels/app-footer-panel';
import { AppTopBar } from './panels/app-top-bar-panel';
import { LoginPage } from '../pages/login/login-page';
import { ResetEmailPage } from '../pages/login/reset-email-page';
import { ResetPasswordPage } from '../pages/login/reset-password-page';

class ShellModule {

    init() {
        const settings = { auth: {}, panels: {} };
        settings.logo = logo;
        settings.routes = allRoutes;
        settings.panels.top = AppTopBar;
        settings.panels.footer = AppFooterPanel;
        settings.sideBar = {enableTranslation: false};
        settings.pages = { auth: {} };
        settings.pages.auth = { register: {}, login: {}, confirm: {}, forget: {} };
        settings.pages.auth.register.hidden = true;
        settings.pages.auth.login = { hidden: false };
        settings.pages.auth.login.view = <LoginPage />;
        settings.pages.auth.forget = { hidden: false };
        settings.pages.auth.forget.view = <ResetEmailPage />;
        settings.pages.auth.confirm = { hidden: false };
        settings.pages.auth.confirm.view = <ResetPasswordPage />;
        settings.auth.loginvm = useLoginViewModel;
        return settings;
    }
}

export { ShellModule };