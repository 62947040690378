export const saveCartItems = (cartItems) => {
    if (localStorage.getItem('cartItems')) {
        localStorage.setItem('cartItems', JSON.stringify(cartItems));
    }
    else localStorage.setItem('cartItems', JSON.stringify(cartItems))
}

export const readCartItems = () => {
    const cartItems = localStorage.getItem('cartItems') ? JSON.parse(localStorage.getItem('cartItems')) : [];
    return cartItems;
}