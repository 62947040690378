import React from "react";
import { Container, Row, Col, FormGroup, Button } from "reactstrap";
import "../styles.scss";
import logo from '../../assets/images/login/logoeyespot_claim.png';
import { AvForm, AvField } from 'availity-reactstrap-validation';
import { useResetPasswordViewModel } from '../../shell/pages/reset-password-vm';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

function ResetPasswordPage(props) {
    const { t } = useTranslation();
    let history = useHistory();

  const [state, dataLoader, dataLoaderAsync] = useResetPasswordViewModel();

  const handleValidSubmit = async (event, values) => {
    await dataLoaderAsync.passwordReset(values.newPassword);
  };

  return (
    <Col md="12" className="login-section">
      <Container fluid={true}>
        <Row className="d-flex align-items-center justify-content-center">
          <Col md="12" className="pt-5">
            <Container fluid={true}>
              <Row className="d-flex justify-content-center">
                <Col md="4" className="my-3 d-flex justify-content-center">
                                  <img className="logo-img img-fluid" src={logo} alt="logo"
                                      onClick={() => history.push(`/dashboard`)}/>
                </Col>
              </Row>
              <Row className="d-flex justify-content-center">
                <Col md="4" className="px-0">
                  <Container fluid={true}>
                    <p className="col-12 title text-center my-3">{t('confirmPage.resetPassword')}</p>
                    <AvForm onValidSubmit={handleValidSubmit} className="col-12">
                      <AvField
                        type="password"
                        name='newPassword'
                        id='newPassword'
                        placeholder={t('confirmPage.enterNewPassword')}
                        validate={{
                          pattern: {
                            value: '/(?=[A-Za-z0-9@#$%^&+!=]+$)^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+!=])(?=.{8,}).*$/',
                            errorMessage: t('confirmPage.errorPassword')
                          },
                        }}
                      />
                      <AvField
                        type="password"
                        name='confirmPassword'
                        id='confirmPassword'
                        placeholder={t('confirmPage.confirmYourPassword')}
                        validate={{
                          match: { value: 'newPassword', errorMessage: t('confirmPage.passwordMatch') }
                        }}
                      />
                      {state.errorMessage ? <p className="error-feedback col-12 text-center m-0">{state.errorMessage}</p> : null}
                      <FormGroup className="col-12 my-3 d-flex justify-content-center">
                        <Button color="login col-6 py-2">{t('confirmPage.submit')}</Button>
                      </FormGroup>
                    </AvForm>
                  </Container>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </Col >
  );
}

export { ResetPasswordPage };