import React from 'react';
import { Redirect, Route } from 'react-router-dom';
import { PandoroPrivateRoute } from '@srlabs-srl/dashboard-hyper';
import { usePandoroMembershipProvider } from '@srlabs-srl/dashboard-hyper';

const membershipProvider = usePandoroMembershipProvider();
const role = membershipProvider.getLoggedInUser()?.role;
const DashboardPage = React.lazy(() => import('../pages/dashboard/dashboard-page'));
const StudyPage = React.lazy(() => import('../pages/studies/create-study-page'));
const StimoliPage = React.lazy(() => import('../pages/stimoli/stimoli-page'));
const AddUser = React.lazy(() => import('../pages/users/add-users-page'));
const MyAccount = React.lazy(() => import('../pages/users/my-account'));
const UsersPage = React.lazy(() => import('../pages/users/users-page'));
// const GroupsPage = React.lazy(() => import('../pages/groups/groups-page'));
const EditUser = React.lazy(() => import('../pages/users/edit-users'));
// const EditGroup = React.lazy(() => import('../pages/groups/edit-groups'));
const EditStudy = React.lazy(() => import('../pages/studies/edit-study-page'));
const Partire = React.lazy(() => import('../pages/studies/partire-page'));
const RealShelf = React.lazy(() => import('../pages/stimoli/real-shelf-page'));
const EditShelfTest = React.lazy(() => import('../pages/stimoli/shelf/edit-fake-shelf-page'));
const EditUploadShelfTest=React.lazy(() => import('../pages/stimoli/shelf/edit-upload-shelf'));
const ErogazioneFakeShelfTest = React.lazy(() => import('../pages/erogazione/erogazione-fakeshelf-page'));
const ErogazionePage = React.lazy(() => import('../pages/erogazione/erogazione-page'));
const ConclusionPage = React.lazy(() => import('../pages/conclusion/conclusion-page'));
const LaunchPage = React.lazy(() => import('../pages/conclusion/launch-page'));
const EditVideoTest = React.lazy(() => import('../pages/stimoli/video/edit-video-test'));
const EditImageTest = React.lazy(() => import('../pages/stimoli/image/edit-image-test'));
const EditSurveyTest = React.lazy(() => import('../pages/stimoli/survey/edit-survey-test'));
const VideoViewsPage = React.lazy(() => import('../pages/stimoli/video/video-views-page'));
const AnalisiPartecipantiPage = React.lazy(() => import('../pages/analisi/analisi-partecipanti-page'));
const AnalisiShelfTestPage = React.lazy(() => import('../pages/analisi/analisi-shelf-test-page'));
const AnalisiShelfEtTestPage = React.lazy(() => import('../pages/analisi/analisi-shelf-et-test-page'));
const AnalisiVideoTestPage = React.lazy(() => import('../pages/analisi/analisi-video-page'));
const AnalisiImageTestPage = React.lazy(() => import('../pages/analisi/analisi-image-page'));
const AnalisiSurveyTestPage = React.lazy(() => import('../pages/analisi/analisi-survey-page'));

const rootRoute = {
    path: '/',
    exact: true,
    component: () => <Redirect to='/dashboard' />,
    route: PandoroPrivateRoute,
};

const dashboardRoutes = {
    path: '/',
    name: 'EyeSpot',
    icon: 'uil-home-alt',
    header: 'Project flow',
    visible: role !== 'User' ? true : false,
    children: [
        {
            path: '/dashboard',
            name: 'Dashboard',
            component: DashboardPage,
            visible: true,
            route: PandoroPrivateRoute,
        },
        {
            path: '/users',
            name: 'Users Page',
            component: UsersPage,
            visible: role !== 'User' ? true : false,
            route: PandoroPrivateRoute,
        },
        {
            path: '/user/edit',
            name: 'Edit User',
            component: EditUser,
            visible: false,
            route: PandoroPrivateRoute,
        },
        {
            path: '/user',
            name: 'Add User',
            component: AddUser,
            visible: false,
            route: PandoroPrivateRoute,
        },
        // {
        //     path: '/group/',
        //     name: 'Edit group',
        //     component: EditGroup,
        //     visible: false,
        //     route: PandoroPrivateRoute,
        // },
        // {
        //     path: '/groups',
        //     name: 'Groups Page',
        //     component: GroupsPage,
        //     visible: role !== 'User' ? true : false,
        //     route: PandoroPrivateRoute,
        // },
        {
            path: '/createstudy',
            name: 'Create Study',
            component: StudyPage,
            visible: false,
            route: PandoroPrivateRoute,
        },
        {
            path: '/myAccount',
            name: 'My Account',
            component: MyAccount,
            visible: false,
            route: PandoroPrivateRoute,
        },
        {
            path: '/editstudy/:id',
            name: 'Edit Study',
            component: EditStudy,
            visible: false,
            route: PandoroPrivateRoute,
        },
        {
            path: '/partire',
            name: 'Partire',
            component: Partire,
            visible: false,
            route: PandoroPrivateRoute,
        },
        {
            path: '/stimoli',
            name: 'Stimoli',
            component: StimoliPage,
            visible: false,
            route: PandoroPrivateRoute,
        },
        {
            path: '/conclusion',
            name: 'Conclusion',
            component: ConclusionPage,
            visible: false,
            route: PandoroPrivateRoute,
        },
        {
            path: '/launch',
            name: 'Launch',
            component: LaunchPage,
            visible: false,
            route: PandoroPrivateRoute,
        },
        {
            path: '/real-shelf',
            name: 'Real Shelf',
            component: RealShelf,
            visible: false,
            route: PandoroPrivateRoute,
        },
        {
            path: '/erogazione-fake-shelf',
            name: 'Erogazione Fake Shelf',
            component: ErogazioneFakeShelfTest,
            visible: false,
            route: PandoroPrivateRoute,
        },
        {
            path: '/erogazione/:studyId/:testId',
            name: 'Erogazione Page',
            component: ErogazionePage,
            visible: false,
            public: true,
            route: Route,
        },
        {
            path: '/edit-shelf-test',
            name: 'Edit Test',
            component: EditShelfTest,
            visible: false,
            route: PandoroPrivateRoute,
        },
        {
            path: '/edit-upload-shelf/:id',
            name: 'Edit Upload Shelf',
            component: EditUploadShelfTest,
            visible: false,
            route: PandoroPrivateRoute,
        },

        {
            path: '/edit-video-test/:id',
            name: 'Edit Video Test',
            component: EditVideoTest,
            visible: false,
            route: PandoroPrivateRoute,
        },
        {
            path: '/analisi-video-test/:id',
            name: 'Analisi Video',
            component: AnalisiVideoTestPage,
            visible: false,
            route: PandoroPrivateRoute,
        },
        {
            path: '/edit-image-test/:id',
            name: 'Edit Image Test',
            component: EditImageTest,
            visible: false,
            route: PandoroPrivateRoute,
        },
        {
            path: '/edit-survey-test/:id',
            name: 'Edit Survey Test',
            component: EditSurveyTest,
            visible: false,
            route: PandoroPrivateRoute,
        },
        {
            path: '/analisi-image-test/:id',
            name: 'Analisi Image',
            component: AnalisiImageTestPage,
            visible: false,
            route: PandoroPrivateRoute,
        },
        {
            path: '/analisi-survey-test/:id',
            name: 'Analisi Survey',
            component: AnalisiSurveyTestPage,
            visible: false,
            route: PandoroPrivateRoute,
        },
        {
            path: '/erogazione-video',
            name: 'Erogazione Video',
            component: VideoViewsPage,
            visible: false,
            route: PandoroPrivateRoute,
        },
        {
            path: '/analisi-page/:id',
            name: 'Analisi Partecipanti',
            component: AnalisiPartecipantiPage,
            visible: false,
            route: PandoroPrivateRoute,
        },
        {
            path: '/analisi-shelf-test/:id',
            name: 'Analisi Shelf Test',
            component: AnalisiShelfTestPage,
            visible: false,
            route: PandoroPrivateRoute,
        },
        {
            path: '/analisi-shelf-et-test/:id',
            name: 'Analisi Shelf ET Test',
            component: AnalisiShelfEtTestPage,
            visible: false,
            route: PandoroPrivateRoute,
        },
    ]
};

const allRoutes = [rootRoute, dashboardRoutes];
export { allRoutes };
