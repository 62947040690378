import React, { useEffect, useState } from "react";
import { Link, useHistory } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  Input,
  FormFeedback,
  Tooltip,
  Form,
  FormGroup,
} from "reactstrap";
// import "../styles.scss";
import logo from "../../assets/images/login/logoeyespot_claim.png";
import usernameIcon from "../../assets/images/login/username-icon.svg";
import passwordIcon from "../../assets/images/login/password-icon.svg";
import { usePandoroMembershipProvider } from "@srlabs-srl/dashboard-hyper";
import { useLoginViewModel } from "../../shell/pages/login-vm";
import { useTranslation } from "react-i18next";
import { MdRemoveRedEye } from "react-icons/md";
import { FiEye, FiEyeOff } from "react-icons/fi";

function LoginPage(props) {
  const history = useHistory();
  const membershipProvider = usePandoroMembershipProvider();
  const [validationError, setValidationError] = useState({
    usernameHasError: null,
    passwordHasError: null,
  });
  const [formError, setFormError] = useState(null)
  const [formValues, setFormValues] = useState({
    username: "",
    password: ""
  })
  const [passwordInputType, setPasswordInputType] = useState("password");
  const { t } = useTranslation();

  const [{ user, error }, dataLoader, dataLoaderAsync] = useLoginViewModel();

  const handleValidSubmit = async (e) => {
    e.preventDefault()
    setFormError(null);
    if (formValues.username.length === 0 || formValues.password.length === 0) {
      return setFormError(t("loginPage.emptyFields"));
    }
    await dataLoaderAsync.login(formValues.username, formValues.password);
  };

  useEffect(() => {
    if (user !== undefined) {
      membershipProvider.setLoggedInUser(user);
      history.go(0);
    }
  }, [user]);

  console.log(validationError);

  return (
    <Col md="12" className="login-section">
      <Container fluid={true}>
        <Row className="d-flex align-items-center justify-content-center">
          <Col md="12" className="pt-5">
            <Container fluid={true}>
              <Row className="d-flex justify-content-center">
                <Col md="4" className="my-3 d-flex justify-content-center">
                  <img
                    className="logo-img img-fluid"
                    src={logo}
                    alt="logo"
                    // onClick={() => history.push(`/dashboard`)}
                  />
                </Col>
              </Row>
              <Row className="d-flex justify-content-center">
                <Col md="4" className="px-0">
                  <Container fluid={true}>
                    <p className="col-12 title text-center my-3">
                      {t("loginPage.register")}
                    </p>
                    <Form
                      onSubmit={(e) => handleValidSubmit(e)}
                      className="col-12">
                      <FormGroup
                        className={`input d-flex justify-content-between align-items-center bg-white ${
                          validationError.usernameHasError
                            ? "border border-danger"
                            : "border border-white"
                        }`}
                        style={{ borderRadius: "50px" }}>
                        <img
                          src={usernameIcon}
                          alt="username"
                          className="ml-3"
                          width="22"
                        />
                        <Input
                          type="text"
                          name="username"
                          className={"border border-white p-3"}
                          id="username"
                          placeholder={t("loginPage.username")}
                          style={{ borderRadius: "50px" }}
                          onBlur={(e) =>
                            setValidationError({
                              usernameHasError:
                                e.target.value.length < 3 ? true : false,
                              passwordHasError:
                                validationError.passwordHasError,
                            })
                          }
                          invalid={validationError.usernameHasError}
                          // required
                          onChange={(e) =>
                            setFormValues({
                              ...formValues,
                              [e.target.name]: e.target.value,
                            })
                          }
                        />
                        {validationError.usernameHasError && (
                          <Tooltip
                            placement="right-end"
                            target={"username"}
                            isOpen={validationError.usernameHasError}
                            style={{ backgroundColor: "red", color: "white" }}>
                            {t("loginPage.fieldInvalid")}
                          </Tooltip>
                        )}
                      </FormGroup>

                      <FormGroup
                        className={`input d-flex justify-content-between align-items-center bg-white mt-3 ${
                          validationError.passwordHasError
                            ? "border border-danger"
                            : "border border-white"
                        }`}
                        style={{ borderRadius: "50px" }}>
                        <img
                          src={passwordIcon}
                          alt="password"
                          className="ml-3"
                          width="16"
                        />
                        <Input
                          type={passwordInputType}
                          name="password"
                          className={"border border-white p-3"}
                          id="password"
                          placeholder={t("loginPage.password")}
                          style={{ borderRadius: "50px" }}
                          onBlur={(e) =>
                            setValidationError({
                              usernameHasError:
                                validationError.usernameHasError,
                              passwordHasError:
                                e.target.value.length < 3 ? true : false,
                            })
                          }
                          invalid={validationError.passwordHasError}
                          // required
                          onChange={(e) =>
                            setFormValues({
                              ...formValues,
                              [e.target.name]: e.target.value,
                            })
                          }
                        />
                        {passwordInputType === "password" ? (
                          <FiEye
                            size={25}
                            className="mr-3 password-reveal-icon"
                            onClick={() =>
                              setPasswordInputType("text")
                            }
                          />
                        ) : (
                          <FiEyeOff
                            size={25}
                            className="mr-3 password-reveal-icon"
                            onClick={() =>
                              setPasswordInputType("password")
                            }
                          />
                        )}
                        {validationError.passwordHasError && (
                          <Tooltip
                            placement="right-end"
                            target={"password"}
                            isOpen={validationError.passwordHasError}
                            style={{ backgroundColor: "red", color: "white" }}>
                            {t("loginPage.fieldInvalid")}
                          </Tooltip>
                        )}
                      </FormGroup>
                      <Col md="12" className="my-3 d-flex align-items-center">
                        <div>
                          <Link
                            to="/account/forget-password"
                            className="forgot-pass mt-1 mx-1">
                            <span>{t("loginPage.forgotPassword")}</span>
                          </Link>
                        </div>
                      </Col>
                      <div>
                        {error ? (
                          <p className="error-feedback col-12 text-center m-0">
                            {error}
                          </p>
                        ) : formError ? (
                          <p className="error-feedback col-12 text-center m-0">
                            {formError}
                          </p>
                        ) : null}
                      </div>
                      <FormGroup className="col-12 my-3 d-flex justify-content-center">
                        <Button color="login col-6 py-2">
                          {t("loginPage.submit")}
                        </Button>
                      </FormGroup>
                    </Form>
                  </Container>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </Col>
  );
}

export { LoginPage };
