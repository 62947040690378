import { gql, useMutation } from "@apollo/client";
import {
  PandoroMembershipUser,
  usePandoroViewModel,
} from "@srlabs-srl/dashboard-hyper";
import { useTranslation } from "react-i18next";

const LOGIN = gql`
  mutation ($username: String!, $password: String!) {
    auth(username: $username, password: $password) {
      accessToken
      refreshToken
      role
      username
    }
  }
`;

function useLoginViewModel() {
  const [login] = useMutation(LOGIN);
  const { t } = useTranslation();
  class LoginReducer {
    async login(state, username, password) {
      try {
        const result = await login({ variables: { username, password } });
        const user = new PandoroMembershipUser();
        const { auth } = result.data;
        user.username = auth.username;
        user.role = auth.role || undefined;
        user.token = auth.accessToken;
        return { user: user, error: null };
      } catch (error) {
        console.log(error.message);
        if (error.message === "401") {
          return { user: undefined, error: t("loginPage.NonActiveLicense") };
        }
        return { user: undefined, error: t("loginPage.wrongMessage") };
      }
    }
  }
  return usePandoroViewModel(LoginReducer);
}

export { useLoginViewModel };
