import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import usernameIcon from '../../assets/images/top_bar_panel/username_icon2.svg';
import { usePandoroMembershipProvider } from '@srlabs-srl/dashboard-hyper';
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem, Row, Col } from 'reactstrap';
import { saveCartItems } from "../../services/localStorage";
import { useTranslation } from 'react-i18next';
import { useQuery, useApolloClient } from "@apollo/client";
import enFlag from '../../assets/images/flag-uk.png';
import itFlag from '../../assets/images/flag-it.png';
import {
    GET_USER_BY_USERNAME
} from "../../pages/graphq";

function AppTopBar(props) {
    const history = useHistory()
    const membershipProvider = usePandoroMembershipProvider();
    const { t, i18n } = useTranslation();
    const [userId, setUserId] = useState(null);

    const userByNameQuery = useQuery(GET_USER_BY_USERNAME, {
        variables: {
            username: membershipProvider.getLoggedInUser().username
        },
        fetchPolicy: "network-only",
    });

    useEffect(() => {
        if (userByNameQuery.data) {
            setUserId(userByNameQuery.data.user.id);
        }
    }, [userByNameQuery.data, userByNameQuery.loading, userByNameQuery.error]);

    const logout = () => {
        membershipProvider.logout();
        saveCartItems([]);
        history.go(0);
    };
    const editUser = () => {
        history.push('/myAccount', { id: userId, role: membershipProvider.getLoggedInUser().role });
    };
    const [dropdownUserOpen, setDropdownUserOpen] = useState(false);
    const [dropdownLanguageOpen, setDropdownLanguageOpen] = useState(false);
    const [selectedLanguage, setSelectedLanguage] = useState({ name: i18n.language === 'en' ? 'English' : 'Italiano', flag: i18n.language === 'en' ? enFlag : itFlag });

    const toggleUserDropdown = () => setDropdownUserOpen(!dropdownUserOpen);
    const toggleLanguageDropdown = () => setDropdownLanguageOpen(!dropdownLanguageOpen);

    const changeLanguage = (value) => {
        i18n.changeLanguage(value);
    }

    const Languages = [
        {
            name: 'English',
            value: 'en',
            flag: enFlag,
        },
        {
            name: 'Italiano',
            value: 'it',
            flag: itFlag,
        }
    ];
    return (
        <ul className="d-flex align-items-center list-unstyled topbar-right-menu float-right mb-0">
            <li className="notification-list topbar-dropdown d-none d-lg-block">
                <Dropdown>
                    <DropdownToggle
                        data-toggle="dropdown"
                        tag="button"
                        className="nav-link dropdown-toggle arrow-none btn btn-link"
                    >
                        <a style={{ color: '#98a6ad' }}
                            target="_blank"
                            href={(selectedLanguage.name != 'Italiano' ? 'https://www.eyespotsolution.com/faq/?lang=en' : 'https://www.eyespotsolution.com/faq/')}>
                            FAQ
                        </a>
                    </DropdownToggle>
                </Dropdown>
            </li>
            <li className="notification-list topbar-dropdown d-none d-lg-block">
                <Dropdown isOpen={dropdownLanguageOpen} toggle={toggleLanguageDropdown}>
                    <DropdownToggle
                        data-toggle="dropdown"
                        tag="button"
                        className="nav-link dropdown-toggle arrow-none btn btn-link"
                        onClick={toggleLanguageDropdown}
                        aria-expanded={dropdownLanguageOpen}
                    >
                        <img
                            src={selectedLanguage.flag}
                            alt={selectedLanguage.name}
                            className="mr-1"
                            height="12"
                        />{' '}
                        <span className="align-middle">{selectedLanguage.name}</span>
                        <i className="mdi mdi-chevron-down align-middle"></i>
                    </DropdownToggle>
                    <DropdownMenu right className="dropdown-menu-animated topbar-dropdown-menu">
                        <div onClick={toggleLanguageDropdown}>
                            {Languages.map((lang, i) => {
                                return (
                                    <DropdownItem
                                        className="dropdown-item notify-item"
                                        key={i + '-lang'}
                                        onClick={() => {
                                            changeLanguage(lang.value)
                                            setSelectedLanguage({
                                                name: lang.name,
                                                flag: lang.flag,
                                            })
                                        }}
                                    >
                                        <img
                                            src={lang.flag}
                                            alt={lang.name}
                                            className="mr-1"
                                            height="12"
                                        />{' '}
                                        <span className="align-middle">{lang.name}</span>
                                    </DropdownItem>
                                )
                            })}
                        </div>
                    </DropdownMenu>
                </Dropdown>
            </li>
            <li className="notification-list">
                <Dropdown isOpen={dropdownUserOpen} toggle={toggleUserDropdown}>
                    <DropdownToggle
                        data-toggle="dropdown"
                        tag="button"
                        className="nav-link dropdown-toggle navUser arrow-none mr-0"
                        onClick={toggleUserDropdown}
                        aria-expanded={dropdownUserOpen}
                    >
                        <span className="account-user-name">{`${
                            membershipProvider.getLoggedInUser().username
                            }`}</span>
                        <span className="account-user-avatar">
                            <img
                                src={usernameIcon}
                                alt="username"
                                className="mx-2"
                                width="30"
                                height="30"
                            />
                        </span>
                    </DropdownToggle>
                    <DropdownMenu
                        right
                        className="dropdown-menu-animated topbar-dropdown-menu profile-dropdown"
                    >
                        <div onClick={toggleUserDropdown}>
                            <div className="dropdown-header noti-title">
                                <h6 className="text-overflow m-0">
                                    {t('topBar.welcome')}{' '}
                                    {`${membershipProvider.getLoggedInUser().username}`}!
                                    </h6>
                            </div>
                            <DropdownItem onClick={editUser}>
                                <span>{t('topBar.profile')}</span>
                            </DropdownItem>
                            <DropdownItem onClick={logout}>
                                <i className={'uil uil-exit mr-1'} />
                                <span>{t('topBar.logout')}</span>
                                {/* <span>Disconnettiti</span> */}
                            </DropdownItem>
                        </div>
                    </DropdownMenu>
                </Dropdown>
            </li>
        </ul>
    )
}

export { AppTopBar };
