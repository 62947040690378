import React from "react";
import { Container, Row, Col, FormGroup, Button } from "reactstrap";
import "../styles.scss";
import logo from '../../assets/images/login/logoeyespot_claim.png';
import { AvForm, AvInput, AvGroup, AvFeedback } from 'availity-reactstrap-validation';
import { useResetEmailViewModel } from '../../shell/pages/reset-email-vm';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';

function ResetEmailPage(props) {
  const [state, dataLoader, dataLoaderAsync] = useResetEmailViewModel();
    const { t } = useTranslation();
    let history = useHistory();

  const handleValidSubmit = async (event, values) => {
    await dataLoaderAsync.emailReset(values.email);
  };

  return (
    <Col md="12" className="login-section">
      <Container fluid={true}>
        <Row className="d-flex align-items-center justify-content-center">
          <Col md="12" className="pt-5">
            <Container fluid={true}>
              <Row className="d-flex justify-content-center">
                <Col md="4" className="my-3 d-flex justify-content-center">
                                  <img className="logo-img img-fluid" src={logo} alt="logo"
                                      onClick={() => history.push(`/dashboard`)}/>
                </Col>
              </Row>
              <Row className="d-flex justify-content-center">
                <Col md="4" className="px-0">
                  <Container fluid={true}>
                    <p className="col-12 title text-center my-3">{t('forgotPage.forgotPassword')}</p>
                    <p className="col-12 my-3">{t('forgotPage.instructions')}</p>
                    <AvForm onValidSubmit={handleValidSubmit} className="col-12">
                      <AvGroup className="input mt-3 d-flex justify-content-between align-items-center">
                        <AvInput
                          type="email"
                          name="email"
                          id="email"
                          placeholder={t('forgotPage.email')}
                          validate={{ email: true }}
                        />
                        <AvFeedback>{t('forgotPage.fieldInvalid')}</AvFeedback>
                      </AvGroup>
                      {state.successMessage && <p className="success-feedback col-12 text-center m-0">{state.successMessage}</p>}
                      {state.errorMessage && <p className="error-feedback col-12 text-center m-0">{state.errorMessage}</p>}
                      <FormGroup className="col-12 my-3 d-flex justify-content-center">
                        <Button color="login col-6 py-2">{t('forgotPage.submit')}</Button>
                      </FormGroup>
                    </AvForm>
                  </Container>
                </Col>
              </Row>
            </Container>
          </Col>
        </Row>
      </Container>
    </Col>
  );
}

export { ResetEmailPage };