import { gql } from '@apollo/client'

export const GET_USERS = gql`
    query {
        users {
            id
            name
            username
            createdAt
            role
        }
    }
`

export const CREATE_USER = gql`
    mutation createUser(
        $name: String!
        $username: String!
        $password: String!
        $role: String!
        $email: String!
    ) {
        createUser(
            name: $name
            username: $username
            password: $password
            role: $role
            email: $email
        ) {
            user {
                id
            }
        }
    }
`

export const DELETE_USER = gql`
    mutation deleteUser($userData: String) {
        deleteUser(userData: $userData) {
            user
        }
    }
`

export const EDIT_USER = gql`
    mutation updateUser($userData: UserEditInput) {
        updateUser(userData: $userData) {
            user {
                id
                username
                name
            }
        }
    }
`

export const ADD_USER_TO_GROUP = gql`
    mutation AddUserToGroup($groupName: String!, $userName: String!) {
        addUserToGroup(groupName: $groupName, userName: $userName) {
            res
        }
    }
`

export const REMOVE_USER_FROM_GROUP = gql`
    mutation removeUserFromGroup($groupName: String!, $userName: String!) {
        removeUserFromGroup(groupName: $groupName, userName: $userName) {
            res
        }
    }
`

export const GET_USER_BY_USERNAME = gql`
    query user($username: ID!) {
        user(username: $username) {
            address
            company
            createdAt
            email
            id
            isActive
            isVerified
            name
            resetPasswordTimeout
            resetPasswordToken
            role
            updatedAt
            username
        }
    }
`

export const GET_USER = gql`
    query user($id: ID!) {
        userById(id: $id) {
            id
            username
            name
            role
            email
        }
        groups {
            id
            name
        }
        groupsUser(id: $id) {
            id
            name
        }
    }
`

export const GET_GROUPS = gql`
    query {
        groups {
            id
            name
        }
    }
`

export const GET_GROUP = gql`
    query groupById($id: ID!) {
        groupById(id: $id) {
            id
            name
            users
        }
        users {
            id
            username
        }
    }
`

export const DELETE_GROUP = gql`
    mutation deleteGroup($groupName: String!) {
        deleteGroup(groupName: $groupName) {
            group
        }
    }
`

export const CREATE_GROUP = gql`
    mutation createGroup($groupName: String!) {
        createGroup(groupName: $groupName) {
            group
        }
    }
`

export const CREATE_STUDY = gql`
    mutation ($studyData: StudyInput!) {
        createStudy(studyData: $studyData) {
            study {
                closeRedirectUrl
                completeRedirectUrl
                createdAt
                description
                generatedLink
                hasMaximum
                id
                isActive
                language
                maxParticipants
                consumeParticipants
                name
                notes
                participants
                quotaFullRedirectUrl
                quote
                redirect
                screenOutRedirectUrl
                sendMail
                status
                studyType
                thanksMessage
                theme
                updatedAt
                user
                welcomeMessage
            }
        }
    }
`

export const GET_FILTERED_STUDIES = gql`
    query studiesExt(
        $user: String!
        $searchString: String!
        $page: Int!
        $order: String
        $opened: Boolean
        $closed: Boolean
        $draft: Boolean
    ) {
        studiesExt(
            user: $user
            searchString: $searchString
            page: $page
            order: $order
            opened: $opened
            closed: $closed
            draft: $draft
        ) {
            closeRedirectUrl
            completeRedirectUrl
            createdAt
            description
            generatedLink
            hasMaximum
            id
            isActive
            language
            maxParticipants
            name
            notes
            participants
            quotaFullRedirectUrl
            quote
            redirect
            screenOutRedirectUrl
            sendMail
            status
            studyType
            thanksMessage
            theme
            updatedAt
            user
            welcomeMessage
        }
        countStudiesExt(
            user: $user
            searchString: $searchString
            opened: $opened
            closed: $closed
            draft: $draft
        )
    }
`

export const CLOSE_STUDY = gql`
    mutation closeStudy($id: String) {
        closeStudy(id: $id) {
            study {
                id
                status
            }
        }
    }
`

export const DELETE_STUDY = gql`
    mutation deleteStudy($studyId: String!) {
        deleteStudy(studyId: $studyId) {
            res
        }
    }
`

export const DELETE_STUDY_AND_ALL_RELATED_BY_STUDY = gql`
    mutation deleteStudyAndAllRelatedByStudy($studyId: String!) {
        deleteStudyAndAllRelatedByStudy(studyId: $studyId) {
            res
        }
    }
`

export const EDIT_STUDY = gql`
    query studyById($studyId: ID!) {
        studyById(studyId: $studyId) {
            closeRedirectUrl
            completeRedirectUrl
            consumeParticipants
            createdAt
            description
            generatedLink
            hasMaximum
            id
            isActive
            language
            maxParticipants
            name
            notes
            participants
            quotaFullRedirectUrl
            quote
            redirect
            screenOutRedirectUrl
            sendMail
            status
            studyType
            thanksMessage
            theme
            updatedAt
            user
            welcomeMessage
        }
    }
`

export const UPDATE_STUDY = gql`
    mutation updateStudy($studyData: StudyInput) {
        updateStudy(studyData: $studyData) {
            study {
                closeRedirectUrl
                completeRedirectUrl
                consumeParticipants
                createdAt
                description
                generatedLink
                hasMaximum
                id
                isActive
                language
                maxParticipants
                name
                notes
                participants
                quotaFullRedirectUrl
                quote
                redirect
                screenOutRedirectUrl
                sendMail
                status
                studyType
                thanksMessage
                theme
                updatedAt
                user
                welcomeMessage
            }
        }
    }
`

export const CREATE_PARTICIPANT_IN_STUDY = gql`
    mutation createParticipantInStudy($participantInStudyData: ParticipantInStudyInput) {
        createParticipantInStudy(participantInStudyData: $participantInStudyData) {
            participantInStudy {
                studyId
                testId
                participantId
            }
        }
    }
`

export const CREATE_STUDY_EVENT = gql`
    mutation createStudyEvent($studyEventData: StudyEventInput) {
        createStudyEvent(studyEventData: $studyEventData) {
            studyEvent {
                studyId
                participantId
                event
                timestamp
                description
                browser
            }
        }
    }
`

export const UPDATE_PARTICIPANT_IN_STUDY = gql`
    mutation updateParticipantInStudy(
        $participant_id: String!
        $studyId: String!
        $participantInStudyData: ParticipantInStudyInput
    ) {
        updateParticipantInStudy(
            participantId: $participantId
            study_id: $studyId
            participantInStudyData: $participantInStudyData
        ) {
            participantInStudy {
                testId
                updatedAt
            }
        }
    }
`

export const DELETE_PARTICIPANT_IN_STUDY = gql`
    mutation deleteParticipantInStudy($studyId: String!, $participantId: String!) {
        deleteParticipantInStudy(studyId: $studyId, participantId: $participantId) {
            res
        }
    }
`

export const DELETE_PARTICIPANTS_AND_ALL_DATA_BY_STUDY = gql`
    mutation deleteParticipantsAndAllDataByStudy($studyId: String!) {
        deleteParticipantsAndAllDataByStudy(studyId: $studyId) {
            res
        }
    }
`

export const DELETE_EYETRACKING_BY_TEST_PARTICIPANT = gql`
    mutation deleteEyetrackingByTestParticipant($testId: String!, $participantId: String!) {
        deleteEyetrackingByTestParticipant(testId: $testId, participantId: $participantId) {
            res
        }
    }
`

export const GET_FAKE_SHELF_TEST = gql`
   query fakeShelfTest($testId: ID!) {
        fakeShelfTest(testId: $testId) {
            name
            notes
            position
            Cls
            completed
            createdAt
            createdBy
            tools
            id
            mainImageUrl
            imageSize
            duration
            imageSize
            eyetracking
            emotions
            mouseClick
            allowEmptyCart
            studyId
            updatedAt
            testUuid
            executedTest
            testType
            processStatus
            stimuli
            products {
                edges {
                    node {
                        productId
                        name
                        description
                        photoBackName
                        photoBackUrl
                        photoFrontName
                        photoFrontUrl
                        price      
                    }
                }
            }
            areas {
                edges {
                   node {
                        productId
                        color
                        regionName
                        time
                        polygonPoints {
                            edges {
                                node {
                                    x
                                    y
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`


export const CREATE_FAKE_SHELF_TEST = gql`
    mutation createFakeShelfTest($testData: FakeShelfTestInput, $productsData: [ProductFSInput],$areasData: [AreaOfInterestFSInput])  {
        createFakeShelfTest( testData: $testData, productsData: $productsData,areasData:$areasData) {
            test {
                Cls
                completed
                createdAt
                createdBy
                description
                id
                name
                notes
                position
                numberShelfs
                studyId
                duration
                tools
                updatedAt
                currency
                mainImageUrl
                duration
                imageSize
                eyetracking
                emotions
                mouseClick
                allowEmptyCart
                stimuli
                products{
                  edges{
                  node{
                    name
                    description
                    photoBackName
                    photoBackUrl
                    photoFrontName
                    photoFrontUrl
                    price      
                  }
                }
               }

               areas {
                edges {
                    node {
                        color
                        regionName
                        time
                        typePolygon
                    }
                }
            }

            }
        }
    } 
`

export const UPDATE_FAKE_SHELF_TEST = gql`
   mutation updateFakeShelfTest($testId: String,$testData: FakeShelfTestInput, $productsData: [ProductFSInput],$areasData: [AreaOfInterestFSInput])  {
        updateFakeShelfTest(testId:$testId , testData: $testData, productsData: $productsData,areasData:$areasData) {
            test {
                Cls
                completed
                createdAt
                createdBy
                description
                id
                name
                notes
                position
                numberShelfs
                studyId
                tools
                eyetracking
                emotions
                mouseClick
                duration
                updatedAt
                currency
                mainImageUrl
                imageSize
                allowEmptyCart
                products{
                  edges{
                  node{
                    name
                    description
                    photoBackName
                    photoBackUrl
                    photoFrontName
                    photoFrontUrl
                    price                            
                  }
                }
               }

               areas {
                edges {
                    node {
                        color
                        regionName
                        time
                        typePolygon
                        polygonPoints {
                          edges {
                             node {
                                x
                                y
                             }
                            }
                        }
                      
                    }
                }
            }

            }
        }
    }
`

export const CREATE_REAL_SHELF_TEST = gql`
    mutation createRealShelfTest($productsData: [ProductInput], $testData: RealShelfTestInput) {
        createRealShelfTest(productsData: $productsData, testData: $testData) {
            test {
                Cls
                completed
                createdAt
                createdBy
                description
                id
                name
                notes
                position
                numberProducts
                numberShelfs
                studyId
                tools
                updatedAt
                currency
                products {
                    pageInfo {
                        hasNextPage
                        hasPreviousPage
                        startCursor
                        endCursor
                    }
                    edges {
                        node {
                            description
                            name
                            amount
                            photoBackName
                            photoBackUrl
                            photoFrontName
                            photoFrontUrl
                            price
                            shelfPosition
                            y
                        }
                        cursor
                    }
                }
            }
        }
    }
`

export const UPDATE_REAL_SHELF_TEST = gql`
    mutation updateRealShelfTest(
        $productsData: [ProductInput]
        $testData: RealShelfTestInput
        $testId: String
    ) {
        updateRealShelfTest(productsData: $productsData, testData: $testData, testId: $testId) {
            test {
                Cls
                completed
                createdAt
                createdBy
                description
                id
                name
                notes
                position
                numberShelfs
                studyId
                tools
                updatedAt
                currency
                products {
                    pageInfo {
                        hasNextPage
                        hasPreviousPage
                        startCursor
                        endCursor
                    }
                    edges {
                        node {
                            description
                            name
                            amount
                            photoBackName
                            photoBackUrl
                            photoFrontName
                            photoFrontUrl
                            price
                            shelfPosition
                            y
                        }
                        cursor
                    }
                }
            }
        }
    }
`

export const GET_REAL_SHELF_TEST = gql`
    query realShelfTest($testId: ID!) {
        realShelfTest(testId: $testId) {
            Cls
            completed
            createdAt
            createdBy
            description
            id
            name
            notes
            position
            numberProducts
            numberShelfs
            studyId
            tools
            updatedAt
            showPrices
            currency
            products {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                edges {
                    node {
                        description
                        name
                        amount
                        photoBackName
                        photoBackUrl
                        photoFrontName
                        photoFrontUrl
                        price
                        shelfPosition
                        y
                    }
                    cursor
                }
            }
        }
    }
`

export const TESTS_IN_STUDY = gql`
    query testsInStudy($studyId: String!) {
        testsInStudy(studyId: $studyId) {
            Cls
            completed
            createdAt
            createdBy
            currency
            description
            executedTest
            id
            name
            notes
            position
            stimuli
            studyId
            testType
            testUuid
            tools
            updatedAt
            processStatus
        }
    }
`

export const DELETE_TEST = gql`
    mutation deleteTest($testId: String!) {
        deleteTest(testId: $testId) {
            res
        }
    }
`

export const DELETE_TEST_AND_ALL_RELATED_BY_STUDY_TEST = gql`
    mutation deleteTestAndAllRelatedByStudyTest($studyId: String!, $testId: String!) {
        deleteTestAndAllRelatedByStudyTest(studyId: $studyId, testId: $testId) {
            res
        }
    }
`

export const CREATE_CART_REAL_SHELF = gql`
    mutation createShoppingCartRealShelf(
        $items: [ShoppingCartItemInput]
        $participantId: String!
        $testId: String!
    ) {
        createShoppingCartRealShelf(items: $items, participantId: $participantId, testId: $testId) {
            shoppingCart {
                Cls
                completedCart
                createdAt
                id
                participantId
                testId
                products {
                    edges {
                        node {
                            description
                            numberItems
                            photoBackName
                            photoBackUrl
                            photoFrontName
                            photoFrontUrl
                            price
                            productName
                            totalAmount
                        }
                    }
                }
            }
        }
    }
`

export const CREATE_CART_FAKE_SHELF = gql`
    mutation createShoppingCartFakeShelf(
        $items: [ShoppingCartItemInput]
        $participantId: String!
        $testId: String!
    ) {
        createShoppingCartFakeShelf(items: $items, participantId: $participantId, testId: $testId) {
            shoppingCart {
                Cls
                completedCart
                createdAt
                id
                participantId
                testId
                products {
                    edges {
                        node {
                            description
                            numberItems
                            photoBackName
                            photoBackUrl
                            photoFrontName
                            photoFrontUrl
                            price
                            productName
                            totalAmount
                        }
                    }
                }
            }
        }
    }
`

export const CREATE_TEST_EVENT = gql`
    mutation createTestEvent($testEventData: TestEventInput) {
        createTestEvent(testEventData: $testEventData) {
            testEvent {
                Cls
                analyzed
                description
                event
                id
                participantId
                product
                productDescription
                quantity
                testId
                studyId
                stimulusId
                timestamp
                widthScreen
                heightScreen
                stimulusWidth
                stimulusHeight
                stimulusX
                stimulusY
            }
        }
    }
`

export const CREATE_MOUSE_EVENT = gql`
    mutation createMouseEvent($mouseEventData: MouseEventInput) {
        createMouseEvent(mouseEventData: $mouseEventData) {
            mouseEvent {
                Cls
                testId
                studyId
                imageId
                participantId
                event
                timestamp
                description
                offsetX
                offsetY
                clickPoints
                widthScreen
                heightScreen
                stimulusWidth
                stimulusHeight
                stimulusX
                stimulusY
            }
        }
    }
`

export const GET_VIDEO_TEST = gql`
    query videoTest($testId: ID!) {
        videoTest(testId: $testId) {
            Cls
            completed
            createdAt
            createdBy
            description
            executedTest
            eyetracking
            emotions
            id
            instructions
            name
            notes
            position
            reproduceSeconds
            reproduceTillEnd
            showFullScreen
            stimuli
            studyId
            testUuid
            tools
            updatedAt
            videoUrl
            processStatus
            areas {
                pageInfo {
                    hasNextPage
                    hasPreviousPage
                    startCursor
                    endCursor
                }
                edges {
                    node {
                        color
                        regionName
                        time
                        typePolygon
                    }
                    cursor
                }
            }
        }
    }
`

export const GET_VIDEO_POINTS = gql`
    query videoTestPoints($testId: ID!) {
        videoTestPoints(testId: $testId)
    }
`

export const CREATE_SURVEY_TEST = gql`
    mutation createSurveyTest($testData: SurveyTestInput, $questionsData: [QuestionInput]) {
        createSurveyTest(testData: $testData, questionsData: $questionsData) {
            test {
                id
            }
        }
    }
`

export const UPDATE_SURVEY_TEST = gql`
    mutation updateSurveyTest(
        $testId: String!
        $testData: SurveyTestInput
        $questionsData: [QuestionInput]
    ) {
        updateSurveyTest(testId: $testId, testData: $testData, questionsData: $questionsData) {
            test {
                id
            }
        }
    }
`

export const UPDATE_PROCESS_STATUS = gql`
    mutation updateProcessStatus($testId: String!, $status: Int!) {
        updateProcessStatus(testId: $testId, status: $status) {
            test {
                id
            }
        }
    }
`

export const UPDATE_STUDY_STATUS_BY_ID = gql`
    mutation updateStudyStatusById($studyId: String!, $status: Int!) {
        updateStudyStatusById(studyId: $studyId, status: $status) {
            study {
                closeRedirectUrl
                completeRedirectUrl
                consumeParticipants
                createdAt
                description
                generatedLink
                hasMaximum
                id
                isActive
                language
                maxParticipants
                name
                notes
                participants
                quotaFullRedirectUrl
                quote
                redirect
                screenOutRedirectUrl
                sendMail
                status
                studyType
                thanksMessage
                theme
                updatedAt
                user
                welcomeMessage
            }
        }
    }
`

export const GET_SURVEY_TEST_BY_TEST = gql`
    query surveyTestByTest($testId: String!) {
        surveyTestByTest(testId: $testId) {
            Cls
            createdAt
            createdBy
            description
            id
            name
            notes
            position
            randomView
            stimuli
            studyId
            testType
            testUuid
            tools
            type
            updatedAt
            questions {
                edges {
                    node {
                        behaviour
                        behaviourValue
                        correctOption
                        description
                        maxAnswersSelectionNumber
                        options
                        questionType
                        randomizeOptions
                        required
                        studyId
                        testId
                        questionId
                    }
                }
            }
        }
    }
`

export const CREATE_USER_ANSWER = gql`
    mutation createUserAnswer($answerData: UserAnswerInput) {
        createUserAnswer(answerData: $answerData) {
            res {
                id
            }
        }
    }
`

export const GET_USER_ANSWER_BY_TEST = gql`
    query userAnswerByTest($testId: String!) {
        userAnswerByTest(testId: $testId) {
            Cls
            id
            studyId
            testId
            participantId
            questionId
            questionType
            question
            answers
            createdAt
        }
    }
`

export const GET_IMAGE_TEST_POINTS = gql`
    query imageTestPoints($testId: ID!) {
        imageTestPoints(testId: $testId)
    }
`

export const EYETRACKING_EMOTION_METRICS_BY_STIMULI = gql`
    query eyetrackingEmotionMetricsByStimuli($testId: String!, $stimuliId: String!) {
        eyetrackingEmotionMetricsByStimuli(testId: $testId, stimuliId: $stimuliId)
    }
`

export const EMOTIONS_AVERAGE_BY_STIMULI = gql`
    query eyetrackingAverageByStimuli($testId: String!, $stimuliId: String!) {
        eyetrackingAverageByStimuli(testId: $testId, stimuliId: $stimuliId)
    }
`

export const METRICS_SURVEY_TEST = gql`
    query metricsSurveyTest($testId: String!) {
        metricsSurveyTest(testId: $testId)
    }
`

export const CREATE_VIDEO_TEST = gql`
    mutation createVideoTest($areasData: [AreaOfInterestInput], $testData: VideoTestInput) {
        createVideoTest(areasData: $areasData, testData: $testData) {
            test {
                Cls
                completed
                createdAt
                createdBy
                description
                executedTest
                eyetracking
                emotions
                id
                instructions
                name
                notes
                position
                reproduceSeconds
                reproduceTillEnd
                showFullScreen
                stimuli
                studyId
                testUuid
                tools
                updatedAt
                videoUrl
                areas {
                    pageInfo {
                        hasNextPage
                        hasPreviousPage
                        startCursor
                        endCursor
                    }
                    edges {
                        node {
                            color
                            regionName
                            time
                            typePolygon
                        }
                        cursor
                    }
                }
            }
        }
    }
`

export const UPDATE_VIDEO_TEST = gql`
    mutation updateVideoTest(
        $areasData: [AreaOfInterestInput]
        $testData: VideoTestInput
        $testId: String
    ) {
        updateVideoTest(areasData: $areasData, testData: $testData, testId: $testId) {
            test {
                Cls
                completed
                createdAt
                createdBy
                description
                executedTest
                eyetracking
                emotions
                id
                instructions
                name
                notes
                position
                reproduceSeconds
                reproduceTillEnd
                showFullScreen
                stimuli
                studyId
                testUuid
                tools
                updatedAt
                videoUrl
                areas {
                    pageInfo {
                        hasNextPage
                        hasPreviousPage
                        startCursor
                        endCursor
                    }
                    edges {
                        node {
                            color
                            regionName
                            time
                            typePolygon
                        }
                        cursor
                    }
                }
            }
        }
    }
`

export const GET_IMAGE_TEST_BY_TEST = gql`
    query imageTestByTest($testId: String!) {
        imageTestByTest(testId: $testId) {
            Cls
            id
            testUuid
            studyId
            position
            createdAt
            updatedAt
            instructions
            name
            notes
            description
            randomView
            tools
            eyetracking
            mouseClick
            emotions
            processStatus
            images {
                edges {
                    node {
                        id
                        decodedId
                        name
                        index
                        url
                        width
                        height
                        showType
                        duration
                        minClicks
                        maxClicks
                        drawClicks
                        exitOnMaxClicks
                        onAoiOnly
                        areas {
                            pageInfo {
                                hasNextPage
                                hasPreviousPage
                                startCursor
                                endCursor
                            }
                            edges {
                                node {
                                    color
                                    regionName
                                    time
                                    typePolygon
                                }
                                cursor
                            }
                        }
                    }
                    cursor
                }
            }
        }
    }
`

export const GET_IMAGE_TEST_BY_STUDY = gql`
    query imageTestByStudy($studyId: String!) {
        imageTestByStudy(studyId: $studyId) {
            Cls
            id
            testUuid
            studyId
            position
            createdAt
            updatedAt
            instructions
            name
            notes
            description
            randomView
            tools
            eyetracking
            mouseClick
            emotions
            processStatus
            images {
                edges {
                    node {
                        id
                        decodedId
                        name
                        index
                        url
                        width
                        height
                        showType
                        duration
                        minClicks
                        maxClicks
                        drawClicks
                        exitOnMaxClicks
                        onAoiOnly
                    }
                    cursor
                }
            }
        }
    }
`

export const GET_IMAGE_TEST_BY_STUDY_TEST = gql`
    query imageTestByStudyTest($studyId: String!, $testId: String!) {
        imageTestByStudyTest(studyId: $studyId, testId: $testId) {
            Cls
            id
            testId
            testUuid
            studyId
            position
            createdAt
            updatedAt
            instructions
            name
            notes
            description
            randomView
            tools
            eyetracking
            mouseClick
            emotions
            processStatus
            images {
                edges {
                    node {
                        id
                        decodedId
                        name
                        index
                        url
                        width
                        height
                        showType
                        duration
                        minClicks
                        maxClicks
                        drawClicks
                        exitOnMaxClicks
                        onAoiOnly
                        areas {
                            pageInfo {
                                hasNextPage
                                hasPreviousPage
                                startCursor
                                endCursor
                            }
                            edges {
                                node {
                                    color
                                    regionName
                                    time
                                    typePolygon
                                    polygonPoints {
                                        edges {
                                            node {
                                                x
                                                y
                                            }
                                        }
                                    }
                                }
                                cursor
                            }
                        }
                    }
                    cursor
                }
            }
        }
    }
`

export const CREATE_IMAGE_TEST = gql`
    mutation createImageTest($testData: ImageTestInput, $imagesData: [ImageInput]) {
        createImageTest(testData: $testData, imagesData: $imagesData) {
            test {
                Cls
                id
                testId
                studyId
                createdAt
                updatedAt
                instructions
                name
                notes
                position
                randomView
                eyetracking
                emotions
                mouseClick
            }
        }
    }
`

export const UPDATE_IMAGE_TEST = gql`
    mutation updateImageTest($testData: ImageTestInput, $imagesData: [ImageInput]) {
        updateImageTest(testData: $testData, imagesData: $imagesData) {
            test {
                Cls
                id
                testId
                studyId
                createdAt
                updatedAt
                instructions
                name
                notes
                position
                randomView
                eyetracking
                emotions
                mouseClick
            }
        }
    }
`

export const UPDATE_IMAGE_AOI = gql`
    mutation updateImageAoi(
        $testId: String!
        $imageId: String!
        $areasData: [AreaOfInterestInput]
    ) {
        updateImageAoi(testId: $testId, imageId: $imageId, areasData: $areasData) {
            test {
                id
            }
        }
    }
`

export const UPDATE_IMAGE_NAME = gql`
    mutation updateImageName($testId: String!, $imageId: String!, $name: String) {
        updateImageName(testId: $testId, imageId: $imageId, name: $name) {
            test {
                id
            }
        }
    }
`

export const GET_PARTICIPANTS_BY_TEST = gql`
    query participantsByTest($testId: String) {
        participantsByTest(testId: $testId)
    }
`

export const GET_PARTICIPANTS_BY_STUDY = gql`
    query participantsByStudy($studyId: String) {
        participantsByStudy(studyId: $studyId)
    }
`

export const GET_PARTICIPANTS_BY_ID_STUDY = gql`
    query participantsByIdStudy($id: String, $studyId: String) {
        participantsByIdStudy(id: $id, studyId: $studyId) {
            participantId
            participantHashedIp
            studyId
        }
    }
`

export const GET_PARTICIPANTS_BY_ID_OR_IP_STUDY = gql`
    query participantsByIdOrIpStudy($id: String, $hashedIp: String, $studyId: String) {
        participantsByIdOrIpStudy(id: $id, hashedIp: $hashedIp, studyId: $studyId) {
            participantId
            participantHashedIp
            studyId
        }
    }
`

export const DELETE_EVENTS = gql`
    mutation deleteEvents($participantId: String!, $testId: String!) {
        deleteEvents(participantId: $participantId, testId: $testId) {
            res
        }
    }
`

export const DELETE_MOUSE_EVENTS = gql`
    mutation deleteMouseEvents($testId: String!, $participantId: String!) {
        deleteMouseEvents(testId: $testId, participantId: $participantId) {
            res
        }
    }
`

export const DELETE_USER_ANSWERS_BY_TEST_PARTICIPANT = gql`
    mutation deleteUserAnswerByTestParticpant($testId: String!, $participantId: String!) {
        deleteUserAnswerByTestParticpant(testId: $testId, participantId: $participantId) {
            res
        }
    }
`

export const CREATE_BLACKLIST_STUDY = gql`
    mutation updateBlacklistDataByStudy($studyId: String!, $excluded: [String]) {
        updateBlacklistDataByStudy(studyId: $studyId, excluded: $excluded) {
            res
        }
    }
`

export const UPDATE_PROCESS_STATUS_BY_STUDY = gql`
    mutation updateStatusByStudy($studyId: String!, $status: Int!) {
        updateStatusByStudy(studyId: $studyId, status: $status) {
            res
        }
    }
`

export const STATISTICS_BY_TEST = gql`
    query statisticsByTest($testId: String) {
        statisticsByTest(testId: $testId)
    }
`

export const STATISTICS_BY_STUDY = gql`
    query statisticsByStudy($studyId: String) {
        statisticsByStudy(studyId: $studyId)
    }
`

export const STATISTICS_CARTS_BY_TEST = gql`
    query statisticsCartsByTest($testId: String) {
        statisticsCartsByTest(testId: $testId)
    }
`

export const STATISTICS_SHELF_TEST = gql`
    query statisticsShelfTest($testId: String) {
        statisticsShelfTest(testId: $testId)
    }
`

export const METRICS_SHELF_TEST = gql`
    query metricsShelf($testId: String) {
        metricsShelf(testId: $testId)
    }
`

export const STATISTICS_CARTS_BY_STUDY = gql`
    query statisticsCartsByStudy($studyId: String) {
        statisticsCartsByStudy(studyId: $studyId)
    }
`

export const ANALYTICS_BY_TEST = gql`
    query analyticsByTest($testId: String) {
        analyticsByTest(testId: $testId) {
            Cls
            emotions
            gazeNormalizedX
            gazeNormalizedY
            gazeScreenX
            gazeScreenY
            id
            participantId
            testId
            timestamp
        }
    }
`
export const ANALYTICS_BY_TEST_PARTICIPANT = gql`
    query analyticsByTestParticipant($testId: String, $participantId: String!) {
        analyticsByTestParticipant(testId: $testId, participantId: $participantId) {
            Cls
            emotions
            gazeNormalizedX
            gazeNormalizedY
            gazeScreenX
            gazeScreenY
            id
            participantId
            testId
            timestamp
        }
    }
`
export const ANALYTICS_BY_TEST_GROUPED_PARTICIPANT = gql`
    query analyticsByTestParticipant($testId: String, $participantId: String!) {
        analyticsByTestParticipant(testId: $testId, participantId: $participantId) {
            Cls
            emotions
            gazeNormalizedX
            gazeNormalizedY
            gazeScreenX
            gazeScreenY
            id
            participantId
            testId
            timestamp
        }
    }
`

export const EYETRACKING_STIMULUS_OUTPUT = gql`
    query eyetrackingStimulus($testId: String!) {
        eyetrackingStimulus(testId: $testId) {
            testId
            testMode
            testType
            heatmapStimulus
            heatmapStimulusAoi
            heatmapStimulusAoiNoName
            clickmap
            clickmapLayer
            clickmapAoi
            clickmapAoiNoName
            clickmapHeatmapStimulus
            clickmapHeatmapStimulusLayer
            clickmapHeatmapStimulusAoi
            clickmapHeatmapStimulusAoiNoName
            metricAoiStimulus
            metricAoiStimulusNoName
            opacityStimulusAoiNoName
            heatmapStimulusLayer
            opacityStimulus
            opacityStimulusAoi
            opacityStimulusLayer
            originalStimulus
            originalStimulusAoi
            originalStimulusAoiNoName
            radius
            blur
            opacity
            maxValue
            threshold
        }
    }
`

export const EYETRACKING_STIMULUS_OUTPUT_BY_STIMULI = gql`
    query eyetrackingStimulusByStimuli($testId: String!, $stimuliId: String!) {
        eyetrackingStimulusByStimuli(testId: $testId, stimuliId: $stimuliId) {
            testId
            testMode
            testType
            heatmapStimulus
            heatmapStimulusAoi
            heatmapStimulusAoiNoName
            clickmap
            clickmapLayer
            clickmapAoi
            clickmapAoiNoName
            clickmapHeatmapStimulus
            clickmapHeatmapStimulusLayer
            clickmapHeatmapStimulusAoi
            clickmapHeatmapStimulusAoiNoName
            metricAoiStimulus
            metricAoiStimulusNoName
            opacityStimulusAoiNoName
            heatmapStimulusLayer
            opacityStimulus
            opacityStimulusAoi
            opacityStimulusLayer
            originalStimulus
            originalStimulusAoi
            originalStimulusAoiNoName
            radius
            blur
            opacity
            maxValue
            threshold
        }
    }
`

export const EYETRACKING_EMOTION_METRICS = gql`
    query eyetrackingEmotionMetrics($testId: String!) {
        eyetrackingEmotionMetrics(testId: $testId)
    }
`

export const GET_EVENTS_BY_TEST_PARTICIPANT_EVENT = gql`
    query eventsByTestParticipantEvent($testId: String!, $participantId: String!, $event: String!) {
        eventsByTestParticipantEvent(
            testId: $testId
            participantId: $participantId
            event: $event
        ) {
            event
        }
    }
`

export const EMOTIONS_AVERAGE = gql`
    query eyetrackingAverage($testId: String!) {
        eyetrackingAverage(testId: $testId)
    }
`

export const GET_EMOTIONS_AVERAGE = gql`
    query getEmotionsAverage($studyId: ID!) {
        studyById(studyId: $studyId) {
            closeRedirectUrl
            completeRedirectUrl
            consumeParticipants
            createdAt
            description
            generatedLink
            hasMaximum
            id
            isActive
            language
            maxParticipants
            name
            notes
            participants
            quotaFullRedirectUrl
            quote
            redirect
            screenOutRedirectUrl
            sendMail
            status
            studyType
            thanksMessage
            theme
            updatedAt
            user
            welcomeMessage
        }
    }
`

export const GET_EVENTS_BY_STUDY_PARTICIPANT_EVENT = gql`
    query eventsByStudyParticipantEvent(
        $studyId: String!
        $participantId: String!
        $event: String!
    ) {
        eventsByStudyParticipantEvent(
            studyId: $studyId
            participantId: $participantId
            event: $event
        ) {
            event
        }
    }
`

export const UPDATE_POSITIONS = gql`
    mutation updatePositions(
        $studyId: String!
        $testId: String!
        $testPositions: Int!
        $testTypeSended: String!
    ) {
        updatePositions(
            studyId: $studyId
            testId: $testId
            testPositions: $testPositions
            testTypeSended: $testTypeSended
        ) {
            res {
                position
            }
        }
    }
`

export const GET_ANALYSIS_TEST_BY_PARTICIPANT = gql`
    query analyticsTestByParticipants($studyId: String!) {
        analyticsTestByParticipants(studyId: $studyId) {
            participantId
            status
            name
            quality
            quality_strict
        }
    }
`

export const GET_PARTICIPANTS_STATUS_BY_STUDY = gql`
    query participantsStatusByStudy($studyId: String!) {
        participantsStatusByStudy(studyId: $studyId)
    }
`

export const GET_BLACK_LIST_PARTICIPANTS = gql`
    query participantsBlackListByStudy($studyId: String!) {
        participantsBlackListByStudy(studyId: $studyId)
    }
`

export const EMOTIONS_ALL_AVERAGE_BY_STIMULI = gql`
    query getEyetrackingByTestId($testId: String!, $stimuliIds:[String]!) {
        getEyetrackingByTestId(testId: $testId, stimuliIds: $stimuliIds)
    }
`


export const IMAGE_MAKE = gql`
  query imageMake(
    $testId: String!
    $stimulusId: String!
    $radius: Int!
    $blur: Int!
    $opacity: Int!
    $renderType: String!
    $maxValue: Float
    $threshold: Float
    $showAoiName: Boolean
  ) {
    imageMake(
      testId: $testId
      stimulusId: $stimulusId
      radius: $radius
      blur: $blur
      opacity: $opacity
      renderType: $renderType
      maxValue: $maxValue
      threshold: $threshold
      showAoiName: $showAoiName
    )
  }
`;

export const GET_SWS_FAT = gql`
    query completedStudyWithCalibDataForAllTestsByTestParticipant($studyId: String!, $participantId: String!) {
        completedStudyWithCalibDataForAllTestsByTestParticipant(studyId: $studyId, participantId: $participantId)
    }
`

export const FAKE_SHELF_METRICS = gql`
    query fakeShelfMetricsByTestId($testId: String!) {
        fakeShelfMetricsByTestId(testId: $testId)
    }
`;

export const PROCESS_STATUS_BY_TEST = gql`
    query processStatusByTest($testId: String) {
        processStatusByTest(testId: $testId){
            testId
            statusStep
            statusPercentage
            description
            createdAt
            updatedAt
        }
    }
`;

export const GET_THEME_BY_STUDY = gql`
    query themeByStudy($studyId: String) {
        themeByStudy(studyId: $studyId){
            id
            studyId
            createdAt
            updatedAt
            showLogo
            srcLogo
            etHoverColor
            etOkColor
            etCancelColor
            etTextColor
            btnColor
            btnHoverColor
            btnTextColor
            bkgSurveyColor
            bkgImageColor
            bkgShelfColor
            bkgVideoColor
        }
    }
`;

export const GET_LICENSES_BY_PARAM = gql`
  query licenseByParam($paramKey: String!, $paramValue: String!) {
    licenseByParam(paramKey: $paramKey, paramValue: $paramValue) {
      id
      userRole
      username
      groupName
      maxParticipants
      maxProjects
      maxTaskImages
      maxTaskNavigation
      maxTaskOther
      maxTaskStore
      maxTaskSurvey
      maxTaskVideo
      maxTaskShelf
      maxStimulusImages
      maxStimulusImageDuration
      maxStimulusVideoDuration
      maxStimulusShelfDuration
      maxParticipantsUsed
      maxProjectsUsed
      maxTaskSurveyUsed
      maxTaskImagesUsed
      maxTaskVideoUsed
      maxTaskShelfUsed
      maxTaskNavigationUsed
      maxTaskStoreUsed
      maxTaskOtherUsed
      maxStimulusImagesUsed
      startDate
      endDate
    }
  }
`;

export const GET_LICENSES = gql`
  query allLicense {
    allLicense {
      id
      userRole
      username
      groupName
      maxParticipants
      maxProjects
      maxTaskImages
      maxTaskNavigation
      maxTaskOther
      maxTaskStore
      maxTaskSurvey
      maxTaskVideo
      maxTaskShelf
      maxStimulusImageDuration
      maxStimulusVideoDuration
      maxStimulusShelfDuration
      startDate
      endDate
      updatedAt
    }
  }
`;

export const CREATE_THEME = gql`
    mutation createTheme($themeData: ThemeInput) {
        createTheme(themeData: $themeData) {
            res {
                id
                studyId
                createdAt
                updatedAt
                showLogo
                srcLogo
                etHoverColor
                etOkColor
                etCancelColor
                etTextColor
                btnColor
                btnHoverColor
                btnTextColor
                bkgSurveyColor
                bkgImageColor
                bkgShelfColor
                bkgVideoColor
            }
        }
    }
`

export const UPDATE_THEME_BY_STUDY = gql`
    mutation updateThemeByStudy($studyId: String!, $themeData: ThemeInput) {
        updateThemeByStudy(studyId: $studyId, themeData: $themeData) {
            res {
                id
                studyId
                createdAt
                updatedAt
                showLogo
                srcLogo
                etHoverColor
                etOkColor
                etCancelColor
                etTextColor
                btnColor
                btnHoverColor
                btnTextColor
                bkgSurveyColor
                bkgImageColor
                bkgShelfColor
                bkgVideoColor
            }
        }
    }
`

export const DELETE_THEME_BY_STUDY = gql`
    mutation deleteThemeByStudy($studyId: String!) {
        deleteThemeByStudy(studyId: $studyId) {
            res
        }
    }
`
export const CREATE_LICENSE = gql`
  mutation createLicense($licenseData: LicenseInput!) {
    createLicense(licenseData: $licenseData) {
      res {
        id
      }
    }
  }
`;

export const UPDATE_LICENSE = gql`
  mutation updateLicenseByUser($username: String!, $licenseData: LicenseInput!) {
    updateLicenseByUser(username: $username, licenseData: $licenseData) {
      res {
        id
      }
    }
  }
`;

export const DELETE_LICENSE = gql`
  mutation deleteLicenseByUser($username: String!) {
      deleteLicenseByUser(username: $username) {
      res
    }
  }
`;

export const CUSTOM_QUERY = (param) => gql`
query licenseByParam($paramKey: String!, $paramValue: String!) {
    licenseByParam(paramKey: $paramKey, paramValue: $paramValue) {
      ${param}
    }
  }

`